import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private _refreshContact = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshContact() {
    return this._refreshContact;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/contact/list`);
  }

  getByUser(user_id, startDate, endDate, contact_statut, customer_statut) {
    return this._httpClient.post(`${environment.apiUrl}/api/contact/${user_id}/by_user`, { startDate, endDate, contact_statut, customer_statut });
  }

  getByCustomer(customer_id) {
    return this._httpClient.get(`${environment.apiUrl}/api/contact/${customer_id}/by_customer`);
  }

  create(customer, date, start_time, duration, type, reachability, delete_callback_appointment, comment, statut, payment_method, center, reason_for_rejection, customer_will_call, date_of_callback_apointment
  ) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/contact/add`, { customer, date, start_time, duration, type, reachability, delete_callback_appointment, comment, statut, payment_method, center, reason_for_rejection, customer_will_call, date_of_callback_apointment })
      .pipe(
        tap(() => {
          this._refreshContact.next()
        })
      );
  }

  createContactAndSubscription(customer, date, start_time, duration, type, reachability, //contact
    comment, statut, payment_method, center, reason_for_rejection, //history
    pack, bySubject, subjects, subscription_duration, start_date, end_date, price, payment_date, free //subscription
  ) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/contact/add`, {
        customer, date, start_time, duration, type, reachability, comment, statut, payment_method, center, reason_for_rejection, pack, bySubject, subjects, subscription_duration, start_date, end_date, price, payment_date, free
      })
      .pipe(
        tap(() => {
          this._refreshContact.next()
        })
      );
  }

  createContactAndFreeSession(customer, date, start_time, duration, type, reachability, //contact
    comment, statut, payment_method, center, reason_for_rejection, //history
    subject, free_session_date //free session
  ) {
    return this._httpClient
      .post(`${environment.apiUrl}/api/contact/add`, { customer, date, start_time, duration, type, reachability, comment, statut, payment_method, center, reason_for_rejection, subject, free_session_date })
      .pipe(
        tap(() => {
          this._refreshContact.next()
        })
      );
  }

  update(id, customer, date, start_time, duration, type, reachability, //contact
    comment, statut, payment_method, center, reason_for_rejection, customer_will_call, date_of_callback_apointment //history
  ) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/contact/${id}/edit`, {
        customer, date, start_time, duration, type, reachability,
        comment, statut, payment_method, center, reason_for_rejection, customer_will_call, date_of_callback_apointment
      })
      .pipe(
        tap(() => {
          this._refreshContact.next()
        })
      );
  }

  updateAndCreateSubscription(id, customer, date, start_time, duration, type, reachability, //contact
    comment, statut, payment_method, center, reason_for_rejection, //history
    pack, bySubject, subjects, subscription_duration, start_date, end_date, price, payment_date, free //subscription
  ) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/contact/${id}/edit`, {
        customer, date, start_time, duration, type, reachability,
        comment, statut, payment_method, center, reason_for_rejection,
        pack, bySubject, subjects, subscription_duration, start_date, end_date, price, payment_date, free
      })
      .pipe(
        tap(() => {
          this._refreshContact.next()
        })
      );
  }

  updateAndCreateFreeSession(id, customer, date, start_time, duration, type, reachability, //contact
    comment, statut, payment_method, center, reason_for_rejection, //history
    subject, free_session_date //free session
  ) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/contact/${id}/edit`, {
        customer, date, start_time, duration, type, reachability,
        comment, statut, payment_method, center, reason_for_rejection,
        subject, free_session_date
      })
      .pipe(
        tap(() => {
          this._refreshContact.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/contact/${id}/delete`)
      .pipe(
        tap(() => {
          this._refreshContact.next()
        })
      );
  }

}
